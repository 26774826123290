<template>
  <div>
    <div class="bg-banner" />
    <div id="login-box">
      <div class="login-banner" />
      <el-form ref="form" :model="form" :rules="rules" class="login-form" auto-complete="on" label-position="left">
        <div class="title-container">
          <h3 class="title">力宾看板登录入口</h3>
        </div>
        <div>
          <el-form-item prop="username">
            <el-input ref="name" v-model="form.username" placeholder="用户名" type="text" tabindex="1" auto-complete="on">
              <span slot="prefix" class="svg-container">
                <svg-icon icon-class="user" />
              </span>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">

            <el-input ref="password" v-model="form.password" :type="passwordType" placeholder="密码" tabindex="2" auto-complete="on" @keyup.enter.native="handleLogin">
              <span slot="prefix" class="svg-container">
                <svg-icon icon-class="password" />
              </span>
              <svg-icon slot="suffix" :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" @click="showPassword" />
            </el-input>
          </el-form-item>
        </div>
        <!-- <el-checkbox v-model="form.remember">记住我</el-checkbox> -->
        <el-button :loading="loading" type="primary" style="width: 100%;" @click.native.prevent="handleLogin">登 录</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
// let Base64 = require('js-base64').Base64
import Cookies from 'js-cookie'
// import { userList } from '@/utils/user.js'
import svgIcon from "@/components/SvgIcon/index.vue"
export default {
  name: 'Login',
  components: { svgIcon },
  data() {
    return {
      form: {
        username: localStorage.login_account || '',
        password: '',
        remember: !!localStorage.login_account
      },
      rules: {
        username: [
          { required: true, trigger: 'blur', message: '请输入用户名' }
        ],
        password: [
          { required: true, trigger: 'blur', message: '请输入密码' }
        ]
      },
      loading: false,
      passwordType: 'password',
      redirect: undefined
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  methods: {
    showPassword() {
      this.passwordType = this.passwordType === 'password' ? '' : 'password'
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleLogin() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          const params = { ...this.form }
          this.$api.user.login(params).then(res => {
            // const md5_token = Base64.encode(res.access_token)
            const token = res.access_token ? res.access_token : undefined
            if (token) {
              Cookies.set('Authorization', res.access_token, { expires: 1 });
              this.$router.push({ name: 'Welcome' })
              this.$message({
                message: '登录成功',
                type: 'success'
              })
            } else {
              this.$message({
                message: '登录失败',
                type: 'error'
              })
            }
          }).catch(() => {
            this.$message({
              message: '登录失败',
              type: 'error'
            })
          }).finally(() => {
            this.loading = false
          })
          // userList.forEach(user => {
          //   if (user.username === this.form.username && user.password === this.form.password) {
          //     const md5_token = Base64.encode(user.Authorization)
          //     Cookies.set('Authorization', md5_token, { expires: 1 });
          //     this.$router.push({ name: 'Welcome' })
          //     this.$message({
          //       message: '登录成功',
          //       type: 'success'
          //     })
          //   } else {
          //     this.$message({
          //       message: '登录失败',
          //       type: 'error'
          //     })
          //   }
          // })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
[data-mode=mobile] {
    #login-box {
        max-width: 80%;
        .login-banner {
            display: none;
        }
    }
}
::v-deep input[type=password]::-ms-reveal {
    display: none;
}
.bg-banner {
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-image: url(~@/assets/images/login-bg.jpg);
    background-size: cover;
    background-position: center center;
}
#login-box {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    background: rgba(255, 255, 255, 0.8);
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 0 5px #999;
    .login-banner {
        width: 300px;
        background-image: url(~@/assets/images/login-banner.jpg);
        background-size: cover;
        background-position: center center;
    }
    .login-form {
        width: 450px;
        padding: 50px 35px 30px;
        overflow: hidden;
        .svg-icon {
            vertical-align: -0.35em;
        }
        .title-container {
            position: relative;
            .title {
                font-size: 22px;
                color: #666;
                margin: 0 auto 30px;
                text-align: center;
                font-weight: bold;
                // @include text-overflow;
            }
        }
    }
    ::v-deep .el-input {
        display: inline-block;
        height: 48px;
        width: 100%;
        input {
            height: 48px;
        }
        .el-input__prefix {
            left: 10px;
        }
        .el-input__suffix {
            right: 10px;
        }
    }
    .el-checkbox {
        margin: 20px 0;
    }
}
</style>
